/* eslint-disable array-callback-return */
/* eslint-disable no-unreachable */
import { groupBy, sortBy } from 'lodash'
import { useMutation } from 'react-query'
import {
	loadActivityStatusList,
	loadAssetRef,
	loadCompositLovData,
	loadExcelBotValidation,
	loadFormFieldList,
	loadFormTagMappingList,
	loadProductCategories,
	loadProductVariants,
	loadProductWorkflow,
	loadProductWorkflowTypes,
	loadRoleAssetRef,
	loadStatusBasedFormList,
	loadSubmittedFormList,
	loadSubStatusList,
	loadFieldValues,
	fieldEditSave,
	loadOnlineDependentList,
	loadOnlineMultiDependentList,
	loadfetchListforRefrence,
	loadWorkflowRef,
	loadWorkflowSearchData,
	validateGroupAccountCode,
	validateGroupAccountName,
	loadExcelFieldValidation,
	loadFormAttachments,
	loadCompositLovState,
	loadAllWorkFlowForms,
	loadAssetLevelFormList,
	saveDocumentRepository,
	loadAutopopulateValues,
	removeFormParticipant,
	addFormParticipant,
	loadDraftFormList,
	sendEmail,
	workforceMappedForms,
	loadFieldHistory,
	validateAnnexExcel,
	formTransactionCheck,
	isCAfCrfActivity,
	loadExcelJson,
	loadCustomerCompanySeal,
	loadAutopopulateLovList,
	loadDynamicFieldsList,
	loadFormDynamicFieldList,
	userIdValidation,
	loadfetchDynamicLovList,
	sendSMS,
	regenerateCafView,
	PaymentChanelData,
	getSignature,
	createOrder,
	gatewayrouting,
	paymentStatusCheck,
	loadFormTagMappingListForDashboard,
	loadAllFormData,
	workDedupeBotCuidCheck,
} from '../services/form.service'
import { getLocalAuthDetails, getMessageUniqueId } from '../utils/common.util'
import { getCurrentDateUTC } from '../utils/date.utils'
import {
	prepareFinalFieldValueForEdit,
	processFormFields,
	processSubmittedFormAccess,
} from '../utils/form.util'
// Listing of Tag Mapping & Origin forms

export function useLoadFormTagMappingList() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await loadFormTagMappingList(payload)
			return res.data.response || []
		} catch (error) {
			return error
		}
	})
}

export function useLoadFormTagMappingListForDashboard() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}
			let res = await loadFormTagMappingListForDashboard(payload)
			return res.data.response || []
		} catch (error) {
			return error
		}
	})
}
// Load form field list
export function useLoadFormFieldList() {
	return useMutation(async (params = {}) => {
		try {
			const { asset_id, asset_token_auth } = getLocalAuthDetails()
			let payload = {
				...params,
				asset_id,
				asset_token_auth,
				start_from: 0,
				limit_value: 1000,
				page_limit: 1000,
				page_start: 0,
			}
			let res = await loadFormFieldList(payload)
			if (res.data.status === 200) {
				const data = res.data.response.data
				return processFormFields(data) || []
			} else {
				return []
			}
		} catch (error) {
			console.log('Error while loading form fields', error)
			return error
		}
	})
}

// load workflow reference data
export function useLoadWorkflowRef() {
	return useMutation(async params => {
		try {
			const payload = {
				page_limit: 50,
				page_start: 0,
				...params,
				...getLocalAuthDetails(),
			}
			const res = await loadWorkflowRef(payload)
			if (res.data.status === 200 && !!res.data.response) {
				return res.data.response.map(value => {
					const {
						activity_id,
						activity_title,
						activity_cuid_1,
						activity_cuid_2,
						activity_cuid_3,
						activity_creator_operating_asset_first_name,
						account_name,
						activity_lead_asset_id,
					} = value

					return {
						label: activity_title,
						value: `${activity_id}|${activity_title}`,
						details: {
							activity_id,
							activity_title,
							activity_cuid_1,
							activity_cuid_2,
							activity_cuid_3,
							operating_asset_first_name:
								activity_creator_operating_asset_first_name,
							account_name,
							activity_lead_asset_id,
						},
						details_web: {
							workflow_activity_id: activity_id,
							workflow_activity_title: activity_title,
							activity_cuid_1,
							activity_cuid_2,
							activity_cuid_3,
							operating_asset_first_name:
								activity_creator_operating_asset_first_name,
							account_name,
							activity_lead_asset_id,
						},
					}
				})
			} else {
				return []
			}
		} catch (err) {
			console.log(err)
		}
	})
}

// load workflow reference data
export function useLoadAssetRef() {
	return useMutation(async params => {
		try {
			const payload = {
				...getLocalAuthDetails(),
				...params,
			}
			const res = await loadAssetRef(payload)
			if (res.data.status === 200 && !!res.data.response) {
				return res.data.response.map(a => {
					const { asset_id, operating_asset_first_name } = a
					return {
						label: operating_asset_first_name,
						value: asset_id,
						details: {
							...a,
						},
					}
				})
			} else {
				return []
			}
		} catch (err) {
			console.log(err)
			return err
		}
	})
}

// load workflow reference data
export function useLoadRoleAssetRef() {
	return useMutation(async params => {
		try {
			const payload = {
				...getLocalAuthDetails(),
				...params,
			}
			const res = await loadRoleAssetRef(payload)
			if (res.data.status === 200 && !!res.data.response) {
				return res.data.response.map(a => {
					const { asset_id, operating_asset_first_name } = a
					return {
						label: operating_asset_first_name,
						value: asset_id,
						details: {
							...a,
						},
					}
				})
			} else {
				return []
			}
		} catch (err) {
			console.log(err)
			return err
		}
	})
}

export function useLoadExcelBotValidation() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
				...getLocalAuthDetails(),
			}
			const res = await loadExcelBotValidation(payload)
			if (res.status === 200 && res.data.response.length > 0) {
				let botOperationInline = JSON.parse(
					res.data.response[0].bot_operation_inline_data
				)
				return botOperationInline
			} else {
				return {}
			}
		} catch (err) {
			console.log(err)
			return err
		}
	})
}

export function useLoadProductCategories() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
				...getLocalAuthDetails(),
				tag_type_category_id: params?.tag_type_category_id
					? params.tag_type_category_id
					: 5,
				tag_type_id: params?.tag_type_id || 0,
				start_from: 0,
				limit_value: 50,
			}
			const res = await loadProductCategories(payload)

			if (res.status === 200 && res.data.response.length > 0) {
				return res.data.response.map(d => {
					const {
						tag_type_category_id,
						tag_type_category_name,
						tag_type_id,
						tag_type_name,
					} = d
					return {
						label: d.tag_name,
						value: d.tag_id,
						details: {
							tag_type_category_id,
							tag_type_category_name,
							tag_type_id,
							tag_type_name,
						},
					}
				})
			}
		} catch (err) {
			console.log(err)
			return err
		}
	})
}

export function useLoadProductWorkflowTypes() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
				...getLocalAuthDetails(),
				start_from: 0,
				limit_value: 50,
			}
			const res = await loadProductWorkflowTypes(payload)
			if (res.status === 200 && res.data.response.length > 0) {
				return res.data.response.map(d => {
					const {
						activity_type_category_id,
						activity_type_category_name,
						tag_id,
						tag_type_id,
					} = d
					return {
						label: d.activity_type_name,
						value: d.activity_type_id,
						details: {
							activity_type_category_id,
							activity_type_category_name,
							tag_id,
							tag_type_id,
						},
					}
				})
			} else {
				return []
			}
		} catch (err) {
			console.log(err)
			return err
		}
	})
}

export function useLoadProductWorkflow() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
				...getLocalAuthDetails(),
			}
			const res = await loadProductWorkflow(payload)
			if (res.status === 200 && res.data.response.length > 0) {
				return res.data.response.map(d => {
					const {
						activity_type_category_id,
						activity_type_category_name,
						activity_type_id,
						activity_type_name,
						activity_datetime_start_expected,
						activity_datetime_end_deferred,
						activity_workbook_url,
					} = d
					return {
						label: d.activity_title,
						value: d.activity_id,
						details: {
							activity_type_category_id,
							activity_type_category_name,
							activity_type_id,
							activity_type_name,
							activity_datetime_start_expected,
							activity_datetime_end_deferred,
							activity_workbook_url,
						},
					}
				})
			} else {
				return []
			}
		} catch (err) {
			console.log(err)
			return err
		}
	})
}

export function useLoadProductVariants() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
				...getLocalAuthDetails(),
			}
			const res = await loadProductVariants(payload)
			if (
				res.status === 200 &&
				!!res.data.response &&
				res.data.response.length > 0
			) {
				let data = res.data.response.map(variant => {
					const {
						activity_cuid_2,
						activity_cuid_3,
						activity_title,
						activity_id,
					} = variant
					return {
						label: activity_title,
						value: activity_id,
						details: {
							activity_cuid_2,
							activity_cuid_3,
						},
					}
				})
				return [
					...data,
					{
						details: {
							activity_cuid_2: null,
							activity_cuid_3: '',
						},
						label: 'Custom variant',
						value: 0,
					},
				]
			} else {
				return [
					{
						details: {
							activity_cuid_2: null,
							activity_cuid_3: '0',
						},
						label: 'Custom variant',
						value: 0,
					},
				]
			}
		} catch (err) {
			console.log(err)
			return err
		}
	})
}

export function useLoadCompositLovState() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			const payload = {
				...params,
				account_id: userData.account_id,
				asset_auth_token: 'ffaac510-56f5-11eb-a3fd-17032ea18681',
				asset_id: userData.asset_id,
				device_os_id: 5,
				entity_id: 0,
				flag: 1,
				organization_id: userData.organization_id,
				page_limit: 30,
				page_start: 0,
				sort_flag: 1,
				type_id: 2004,
				workforce_id: userData.workforce_id,
			}
			let res = await loadCompositLovData(payload)
			if (res.data.status === 200) {
				let list = res.data.response
				const state = list.map(s => {
					const { lov_id, lov_name } = s
					return {
						label: lov_name,
						value: lov_id,
						details: {
							lov_id,
							lov_name,
						},
					}
				})
				return state
			} else {
				return []
			}
		} catch (err) {
			console.log('Error on LOV state', err)
			return err
		}
	})
}
export function useLoadCompositLovCity() {
	return useMutation(async params => {
		try {
			const payload = {
				...params,
				...getLocalAuthDetails(),
				type_id: 2005,
				// entity_id: state_id,
				// search_string: srchStr,
				flag: 1,
				sort_flag: 1,
				page_start: 0,
				page_limit: 200,
			}
			let res = await loadCompositLovData(payload)
			if (res.data.status === 200) {
				let list = res.data.response
				return list.map(s => {
					const { lov_id, lov_name } = s
					return {
						label: lov_name,
						value: lov_id,
						details: {
							lov_id,
							lov_name,
						},
					}
				})
			} else {
				return []
			}
		} catch (err) {
			console.log('Error on LOV state', err)
			return err
		}
	})
}
export function useLoadCompositLovZipCode() {
	return useMutation(async params => {
		const payload = {
			...params,
			...getLocalAuthDetails(),
			type_id: 2006,
			// entity_id: city_id,
			// search_string: srchStr,
			flag: 1,
			sort_flag: 1,
			page_start: 0,
			page_limit: 30,
			device_os_id: 5,
		}
		let res = await loadCompositLovData(payload)
		if (res.data.status === 200) {
			let list = res.data.response
			return list.map(s => {
				const { lov_id, lov_name } = s
				return {
					label: lov_name,
					value: lov_id,
					details: {
						lov_id,
						lov_name,
					},
				}
			})
		} else {
			return []
		}
		try {
		} catch (err) {
			console.log('Error on LOV state', err)
			return err
		}
	})
}

export function useValidateGroupAccountName() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				flag: 3,
			}
			let res = await validateGroupAccountName(payload)
			let resData = res.data.response[0]
			if (res.data.status === 200) {
				return {
					isError: false,
					...resData,
				}
			} else {
				return {
					isError: true,
					...resData,
				}
			}
		} catch (error) {
			return error
		}
	})
}

export function useValidateGroupAccountCode() {
	return useMutation(async (params = {}) => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				flag: 3,
			}
			let res = await validateGroupAccountCode(payload)
			let resData = res.data.response[0]
			if (res.data.status === 200) {
				return {
					isError: false,
					...resData,
				}
			} else {
				return {
					isError: true,
					...resData,
				}
			}
		} catch (error) {
			return error
		}
	})
}

export function useSearchWorkflowByTags() {
	return useMutation(async (params = {}) => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
				auth_asset_id: userData.asset_id,
			}
			let res = await loadWorkflowSearchData(payload)
			if (res.data.status === 200) {
				const fileData = res.data.response
				if (!!fileData) {
					const optionList = []
					fileData.forEach(value => {
						const {
							activity_id,
							activity_title,
							activity_cuid_1,
							activity_cuid_2,
							activity_cuid_3,
							activity_creator_operating_asset_first_name,
						} = value
						const option = {
							label: activity_title,
							value: activity_id,
							details: {
								activity_id,
								activity_title,
								activity_cuid_1,
								activity_cuid_2,
								activity_cuid_3,
								operating_asset_first_name:
									activity_creator_operating_asset_first_name,
							},
						}
						optionList.push(option)
					})
					let wList = optionList.map(item => ({
						value: item.value,
						label: item.label,
						details: item.details,
					}))
					return wList
				} else {
					return res.data.response
				}
			}
		} catch (error) {
			return error
		}
	})
}

export function useLoadAllFormList() {
	return useMutation(async ({ statusBasedForms, submittedForms }) => {
		try {
			const auth = getLocalAuthDetails()
			let { data } = await loadSubmittedFormList({
				...submittedForms,
				...auth,
				user_workforce_id: auth.workforce_id,
				user_account_id: auth.account_id,
				access_level_id: 0,
				track_latitude: 0,
				track_longitude: 0,
				track_gps_accuracy: 0,
				track_gps_status: 0,
				track_gps_location: 0,
				message_unique_id: getMessageUniqueId(),
				log_datetime: '1970-01-01 00:00:00',
				track_gps_datetime: getCurrentDateUTC(),
			})

			let allWorkflowPayload = {
				...getLocalAuthDetails(),
				start_from: 0,
				limit_value: 50,
				access_level_id: 0,
				account_id: 0,
				workforce_id: 0,
				activity_id: submittedForms.activity_id,
				activity_type_id: submittedForms.activity_type_id,
				activity_type_category_id: submittedForms.activity_type_category_id,
				flag: 1,
				form_id: 0,
				track_gps_accuracy: 0,
				track_gps_location: 0,
				track_gps_status: 0,
				track_latitude: 0,
				track_longitude: 0,
				user_workforce_id: auth.workforce_id,
				user_account_id: auth.account_id,
				log_datetime: '1970-01-01 00:00:00',
				track_gps_datetime: getCurrentDateUTC(),
				message_unique_id: getMessageUniqueId(auth.asset_id),
			}

			let { data: allWorkflowForms } = await loadAllWorkFlowForms(
				allWorkflowPayload
			)
			let { data: draftList } = await loadDraftFormList({
				...auth,
				activity_type_category_id: submittedForms.activity_type_category_id,
				form_id: 0,
				workflow_activity_id: submittedForms.activity_id,
			})
			let groupDraftForms = groupBy(draftList.response, 'form_id')
			let groupSubmittedForms = groupBy(data.response, 'data_form_id')
			let submittedFormList = Object.keys(groupSubmittedForms).map(fi => {
				if (groupSubmittedForms[fi].length > 0) {
					return {
						...groupSubmittedForms[fi][0],
						submittedList: groupSubmittedForms[fi],
					}
				} else {
					return {
						...groupSubmittedForms[fi][0],
					}
				}
			})
			let { data: statusFormData } = await loadStatusBasedFormList({
				...statusBasedForms,
				...auth,
				target_asset_id: auth.asset_id,
				limit_value: 100,
				page_limit: 100,
				page_start: 0,
				start_from: 0,
			})

			let formList = []

			if (data.status === 200) {
				if (data.response.length > 0) {
					let dict = []
					formList = statusFormData.response.map(f => {
						let submittedForm = submittedFormList.find(i => {
							if (i.data_form_id === f.form_id) {
								dict.push(f.form_id)
								return true
							} else {
								return false
							}
						})
						let draftFormIds = Object.keys(groupDraftForms).map(d_id => {
							return Number(d_id)
						})

						let wForm = (allWorkflowForms.response || []).find(
							ws => ws.form_id === f.form_id
						)

						if (!!submittedForm) {
							return {
								...submittedForm,
								isSubmitted:
									f.form_submission_type_id === 2 ? false : !!submittedForm,
								isOrder: f.form_submission_type_id === 2 ? true : false,
								...f,
								form_id: submittedForm.data_form_id,
								form_name: submittedForm.data_form_name,
								activity_id: submittedForm.activity_id,
								operating_asset_id: submittedForm.operating_asset_id,
								log_datetime: submittedForm.log_datetime,
								form_transaction_id: submittedForm.data_form_transaction_id,
								isDraft: !!draftFormIds.includes(f.form_id),
								activity_type_flag_enable_draft:
									!!wForm && wForm.activity_type_flag_enable_draft
										? wForm.activity_type_flag_enable_draft
										: 0,
								draftList: !!draftFormIds.includes(f.form_id)
									? groupDraftForms[f.form_id]
									: [],
								form_config_roms: !!wForm ? wForm?.form_config_roms : {},
								submitted_log_asset_id: 0,
								data_entity_inline: submittedForm?.data_entity_inline,
								form_flag_enable_external_link: !!wForm
									? wForm?.form_flag_enable_external_link
									: '',
								form_static_subject_text: !!wForm
									? wForm?.form_static_subject_text
									: {},
								data_form_transaction_id:
									submittedForm.data_form_transaction_id,
								asset_id: submittedForm?.log_asset_id,
							}
						} else {
							return {
								isSubmitted:
									f.form_submission_type_id === 2 ? false : !!submittedForm,
								isOrder: f.form_submission_type_id === 2 ? true : false,
								...f,
								draftList: !!draftFormIds.includes(f.form_id)
									? groupDraftForms[f.form_id]
									: [],
								isDraft: !!draftFormIds.includes(f.form_id),
								activity_type_flag_enable_draft:
									!!wForm && wForm.activity_type_flag_enable_draft
										? wForm.activity_type_flag_enable_draft
										: 0,
								form_config_roms: !!wForm ? wForm?.form_config_roms : {},
								submitted_log_asset_id: 0,
								data_entity_inline: '{}',
								form_flag_enable_external_link: !!wForm
									? wForm?.form_flag_enable_external_link
									: '',
								form_static_subject_text: !!wForm
									? wForm?.form_static_subject_text
									: {},
							}
						}
					})
					const remainingForms = submittedFormList
						.map(sf => {
							let wsid = (allWorkflowForms.response || []).find(
								ws => ws.form_id === sf.data_form_id
							)
							let rb = (statusFormData.response || []).find(
								r => r.form_id === sf.data_form_id
							)
							if (
								!dict.includes(sf.data_form_id) &&
								wsid?.form_submission_type_id !== 2
							) {
								return {
									form_id: sf.data_form_id,
									form_name: sf.data_form_name,
									activity_id: sf.activity_id,
									operating_asset_id: sf.operating_asset_id,
									log_datetime: sf.log_datetime,
									form_transaction_id: sf.data_form_transaction_id,
									isSubmitted: true,
									isOrder: true,
									form_sequence_id: wsid?.form_sequence_id,
									form_flag_consolidation_enabled:
										wsid?.form_flag_consolidation_enabled,
									form_config_roms: wsid?.form_config_roms,
									activity_status_disable_rollback_refill: !!rb
										? rb.activity_status_disable_rollback_refill
										: 0,
									form_flag_prefill_enabled: wsid?.form_flag_prefill_enabled,
									submitted_log_asset_id: sf?.log_asset_id,
									data_entity_inline: sf?.data_entity_inline,
									form_flag_enable_external_link: !!wsid
										? wsid?.form_flag_enable_external_link
										: '',
									form_static_subject_text: !!wsid
										? wsid?.form_static_subject_text
										: {},
									data_form_transaction_id: sf.data_form_transaction_id,
								}
							}
						})
						.filter(Boolean)

					formList = [...formList, ...remainingForms]
					//formList = [...formList];
				}
			} else {
				formList = statusFormData.response || []
			}
			return formList
		} catch (error) {
			console.log('Error while loading forms list')
			throw new Error(error.message)
		}
	})
}

export function useLoadWorkFlowList() {
	return useMutation(async ({ statusBasedForms, submittedForms }) => {
		try {
			const auth = getLocalAuthDetails()
			let allWorkflowPayload = {
				...getLocalAuthDetails(),
				start_from: 0,
				limit_value: 50,
				access_level_id: 0,
				account_id: 0,
				workforce_id: 0,
				activity_id: submittedForms.activity_id,
				activity_type_id: submittedForms.activity_type_id,
				activity_type_category_id: submittedForms.activity_type_category_id,
				flag: 1,
				form_id: 0,
				track_gps_accuracy: 0,
				track_gps_location: 0,
				track_gps_status: 0,
				track_latitude: 0,
				track_longitude: 0,
				user_workforce_id: auth.workforce_id,
				user_account_id: auth.account_id,
				log_datetime: '1970-01-01 00:00:00',
				track_gps_datetime: getCurrentDateUTC(),
				message_unique_id: getMessageUniqueId(auth.asset_id),
			}

			let { data: allWorkflowForms } = await loadAllWorkFlowForms(
				allWorkflowPayload
			)
			return allWorkflowForms.response
		} catch (error) {
			throw new Error(error.message)
		}
	})
}

export function useLoadSubmittedFormList() {
	return useMutation(async params => {
		try {
			const auth = getLocalAuthDetails()
			let payload = {
				...params,
				...auth,
				user_workforce_id: auth.workforce_id,
				user_account_id: auth.account_id,
				access_level_id: 0,
				track_latitude: 0,
				track_longitude: 0,
				track_gps_accuracy: 0,
				track_gps_status: 0,
				track_gps_location: 0,
				message_unique_id: getMessageUniqueId(),
				log_datetime: '1970-01-01 00:00:00',
				track_gps_datetime: getCurrentDateUTC(),
			}

			let res = await loadSubmittedFormList(payload)
			if (res.data.status === 200) {
				if (res.data.response.length > 0) {
					return res.data.response.map(form => ({
						form_id: form.data_form_id,
						form_name: form.data_form_name,
						activity_id: form.activity_id,
						operating_asset_id: form.operating_asset_id,
						log_datetime: form.log_datetime,
						form_transaction_id: form.data_form_transaction_id,
						isSubmitted: true,
					}))
				} else {
					return []
				}
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadAssetLevelFormList() {
	return useMutation(async params => {
		try {
			const auth = getLocalAuthDetails()
			let payload = {
				...auth,
				differential_datetime: '1970-01-01 00:00:00',
				flag: 0,
				start_from: 0,
				limit_value: 50,
			}

			let res = await loadAssetLevelFormList(payload)
			if (res.data.status === 200) {
				if (res.data.response.length > 0) {
					return res.data.response
				} else {
					return []
				}
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadStatusBasedFormList() {
	return useMutation(async params => {
		try {
			const { asset_id, ...rest } = getLocalAuthDetails()
			let payload = {
				...params,
				...rest,
				asset_id,
				target_asset_id: asset_id,
				limit_value: 100,
				page_limit: 100,
				page_start: 0,
				start_from: 0,
			}

			let res = await loadStatusBasedFormList(payload)
			if (res.data.status === 200) {
				if (res.data.response.length > 0) {
					return res.data.response
				} else {
					return []
				}
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadActivityStatusList() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
				page_limit: 50,
				page_start: 0,
				...getLocalAuthDetails(),
			}

			let res = await loadActivityStatusList(payload)
			if (res.data.status === 200) {
				if (res.data.response.length > 0) {
					return groupBy(res.data.response, 'activity_status_type_id')
				} else {
					return []
				}
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadSubStatusList() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
				page_limit: 50,
				page_start: 0,
				...getLocalAuthDetails(),
			}

			let res = await loadSubStatusList(payload)
			if (res.data.status === 200) {
				if (res.data.response.length > 0) {
					return res.data.response
				} else {
					return []
				}
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

// Save field value
export function useFieldEditSave() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				message_unique_id: getMessageUniqueId(),
				track_latitude: 0,
				track_longitude: 0,
				track_gps_accuracy: 0,
				track_gps_status: 0,
				track_gps_location: '',
				track_gps_datetime: getCurrentDateUTC(),
				device_os_id: 5,
				activity_type_category_id: 9,
			}

			let res = await fieldEditSave(payload)
			if (res.data.status === 200) {
				if (res.data.response.length > 0) {
					return res.data.response
				} else {
					return []
				}
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}
export function useFetchLovListForRefrence() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				page_limit: 50,
				page_start: 0,
			}

			let res = await loadfetchListforRefrence(payload)
			if (res.data.status === 200) {
				if (res.data?.response?.length > 0) {
					const fileData = res.data.response
					const optionList = await prepareLovListOption(fileData)
					res.data.response = optionList
					return res
				} else {
					res.data.response = []
					return res
				}
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

// Get form fields and their data
export function useLoadFormFieldListData() {
	return useMutation(
		async ({
			fieldList: fieldListParams,
			fieldValues,
			is_from_timeline = false,
			submitted_data = [],
		}) => {
			try {
				let resultSet = []
				let fieldList = []

				const { asset_id, asset_token_auth } = getLocalAuthDetails()
				let { data } = await loadFormFieldList({
					asset_id,
					asset_token_auth,
					...fieldListParams,
					start_from: 0,
					limit_value: 1000,
					page_limit: 1000,
					page_start: 0,
				})

				let { data: fieldsData } = await loadFieldValues({
					...getLocalAuthDetails(),
					...fieldValues,
				})
				if (data.status === 200 && fieldsData.status === 200) {
					fieldList = processFormFields(data.response.data) || []
					resultSet = fieldsData.response

					if (resultSet.length > 0) {
						resultSet = fieldsData.response[0].activity_inline_data.filter(
							d => !!d.field_value
						)

						if (!!is_from_timeline) {
							resultSet = submitted_data.map((item, i) => ({
								...item,
								sort_id: i,
								activity_id: fieldsData.response[0].activity_id,
							}))
						} else {
							resultSet = resultSet.map((item, i) => ({
								...item,
								sort_id: i,
								activity_id: fieldsData.response[0].activity_id,
							}))
						}
					}

					let finalList = fieldList.map(item => {
						const fieldItem = resultSet.find(
							field => item.field_id === Number(field.field_id)
						)
						if (!!fieldItem) {
							return {
								...item,
								sort_id: fieldItem?.sort_id,
								data: {
									final: fieldItem,
									field_value: prepareFinalFieldValueForEdit(fieldItem, item), //fieldItem.field_value,
								},
							}
						}
						return item
					})
					return sortBy(finalList, ['field_sequence_id'])
				} else {
					return []
				}
			} catch (error) {
				console.log(error)
				throw new Error(error.message)
			}
		}
	)
}

export function useLoadOnlineDependentsList() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
				page_limit: 50,
				page_start: 0,
			}
			const res = await loadOnlineDependentList(payload)
			if (res.data.status === 200) {
				if (res.data?.response?.length > 0) {
					const fileData = res.data.response
					const optionList = await prepareLovListOption(fileData)
					res.data.response = optionList
					return res
				} else {
					res.data.response = []
					return res
				}
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

const prepareLovListOption = fileData => {
	return fileData.map(value => {
		const { lov_id, lov_name, is_mandatory = '' } = value
		return {
			label: lov_name,
			value: lov_name, // lov_id, //`${lov_id}|${lov_name}`,
			details: {
				lov_name,
				lov_id,
				is_mandatory,
			},
		}
	})
}

export function useLoadExcelBotValidationScheme() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
				page_start: 0,
				page_limit: 100,
			}
			const res = await loadExcelFieldValidation(payload)
			let botOperationInline = JSON.parse(
				!!res.data.response[0]
					? res.data.response[0].bot_operation_inline_data
					: '{}'
			)
			res.data.response = botOperationInline
			return res
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadFormAttachments() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await loadFormAttachments(payload)
			return res
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadAllWorkflowForms() {
	return useMutation(
		async ({
			activity,
			submittedFormList,
			statusBasedFormList,
			assetBasedFormList,
			visiblity,
		}) => {
			try {
				const { activity_id, activity_type_id, activity_type_category_id } =
					activity
				const { asset_id, account_id, workforce_id } = getLocalAuthDetails()
				let payload = {
					...getLocalAuthDetails(),
					start_from: 0,
					limit_value: 50,
					access_level_id: 0,
					account_id: 0,
					workforce_id: 0,
					activity_id,
					activity_type_id,
					activity_type_category_id,
					flag: 1,
					form_id: 0,
					track_gps_accuracy: 0,
					track_gps_location: 0,
					track_gps_status: 0,
					track_latitude: 0,
					track_longitude: 0,
					user_account_id: account_id,
					user_workforce_id: workforce_id,
					log_datetime: '1970-01-01 00:00:00',
					track_gps_datetime: getCurrentDateUTC(),
					message_unique_id: getMessageUniqueId(asset_id),
				}
				let workflowFormList = []

				let res = await loadAllWorkFlowForms(payload)
				console.log('data2', res.data)
				if (res.data.response.length > 0) {
					const refinedWorkflowFormList = res.data.response.filter(
						form => !form.form_flag_consolidation_enabled
					)
					workflowFormList = await processSubmittedFormAccess({
						refinedWorkflowFormList,
						submittedFormList,
						statusBasedFormList,
						assetBasedFormList,
						visiblity,
					})
				} else {
					throw new Error('No forms available')
				}
				return workflowFormList
			} catch (error) {
				return error
			}
		}
	)
}

export function useSaveDocumentRepo() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await saveDocumentRepository(payload)
			return res.data.response
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadAutopopulateValues() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			const res = await loadAutopopulateValues(payload)
			if (res.status === 200) {
				return res.data.response
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useAddFormParticipant() {
	return useMutation(async ({ form, activity_id, selectedUsers }) => {
		try {
			const { form_id, form_name } = form
			const formUserMaps = selectedUsers.map(user => {
				const {
					contact_asset_id,
					contact_first_name,
					contact_operating_asset_id,
					contact_operating_asset_name,
				} = user.data
				return {
					asset_id: contact_asset_id,
					asset_first_name: contact_first_name,
					asset_last_name: '',
					operating_asset_id: contact_operating_asset_id,
					operating_asset_first_name: contact_operating_asset_name,
					operating_asset_last_name: '',
					form_id,
					form_name,
				}
			})

			let requestData = {
				...getLocalAuthDetails(),
				workflow_activity_id: activity_id,
				participant_collection: JSON.stringify({
					form_fill_request: formUserMaps,
				}),
			}
			const res = await addFormParticipant(requestData)
			if (res.status === 200) {
				return res.data.status
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useRemoveFormParticipant() {
	return useMutation(async ({ payload }) => {
		try {
			let requestData = {
				...getLocalAuthDetails(),
				...payload,
			}
			const res = await removeFormParticipant(requestData)
			if (res.status === 200) {
				return res.data.status
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useSendEmail() {
	return useMutation(async ({ payload }) => {
		try {
			let requestData = {
				...payload,
			}
			const res = await sendEmail(requestData)
			if (res.status === 200) {
				return res.data
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useWorkforceFormMap() {
	return useMutation(async payload => {
		try {
			let requestData = {
				...payload,
				...getLocalAuthDetails(),
			}
			const res = await workforceMappedForms(requestData)
			if (res.status === 200) {
				return res.data.response
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadFieldHistory() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await loadFieldHistory(payload)
			if (res.status === 200) {
				return res.data.response
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useValidateAnnexureExcel() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await validateAnnexExcel(payload)
			return res.data
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useFormTransactionCheck() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await formTransactionCheck(payload)
			return res.data.response
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

// Check for ROMS CAF/CRF Activity
export function useCafCrfActivityCheck() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await isCAfCrfActivity(payload)
			return res.data.response
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

// Load excel json list
export function useExcelJsonList() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await loadExcelJson(payload)
			return res.data.response
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

// Load excel json list
export function useLoadFieldValues() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await loadFieldValues(payload)
			return res.data.response
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

// Get form fields and their data for Roms
export function useLoadRomsFormFieldListData() {
	return useMutation(async ({ fieldList: fieldListParams, fieldValues }) => {
		try {
			let resultSet = []
			let fieldList = []

			const { asset_id, asset_token_auth } = getLocalAuthDetails()
			let { data } = await loadFormFieldList({
				...fieldListParams,
				asset_id,
				asset_token_auth,
				start_from: 0,
				limit_value: 1000,
				page_limit: 1000,
				page_start: 0,
			})

			let { data: fieldsData } = await loadFieldValues({
				...fieldValues,
				...getLocalAuthDetails(),
			})
			if (data.status === 200 && fieldsData.status === 200) {
				fieldList = processFormFields(data.response.data) || []
				resultSet = fieldsData.response

				if (resultSet.length > 0) {
					resultSet = fieldsData.response[0].activity_inline_data
				}
				let validatedArray = {}
				fieldList.map(item => {
					const fieldItem = resultSet.find(
						field => item.field_id === Number(field.field_id)
					)
					let obj = {
						...item,
						value: fieldItem?.field_value || '',
						valid: fieldItem?.field_validated || '',
						label: item.field_name,
					}
					validatedArray[item.field_id] = obj
				})
				return validatedArray
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

// Load customer company seal
export function useLoadCompanySeal() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await loadCustomerCompanySeal(payload)
			return res.data.response
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useFetchAutoPopulateLov() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
				page_limit: 50,
				page_start: 0,
			}

			let res = await loadAutopopulateLovList(payload)
			if (res.data.status === 200) {
				return res.data.response
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadOnlineMultiDependentsList() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
				page_limit: 50,
				page_start: 0,
			}
			const res = await loadOnlineMultiDependentList(payload)
			if (res.data.status === 200) {
				if (res.data?.response?.length > 0) {
					const fileData = res.data.response
					const optionList = await prepareLovListOption(fileData)
					res.data.response = optionList
					return res
				} else {
					res.data.response = []
					return res
				}
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadDynamicFieldsList() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
				page_limit: 50,
				page_start: 0,
				start_from: 0,
				limit_value: 50,
			}
			const res = await loadDynamicFieldsList(payload)
			if (res.data.status === 200) {
				if (res.data.response.length > 0) {
					return res.data.response
				} else {
					return []
				}
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useLoadCompositLovMultiList() {
	return useMutation(async params => {
		const payload = {
			...params,
			...getLocalAuthDetails(),
			flag: 1,
			sort_flag: 1,
			page_start: 0,
			page_limit: 30,
			device_os_id: 5,
		}
		let res = await loadCompositLovData(payload)
		if (res.data.status === 200 && !!res.data.response) {
			let list = res.data.response
			return {
				[payload.type_id]: list.map(s => {
					const { lov_id, lov_name } = s
					return {
						label: lov_name,
						value: lov_id,
						details: {
							lov_id,
							lov_name,
						},
					}
				}),
			}
		} else {
			return { [payload.type_id]: [] }
		}
	})
}

// Load form field list
export function useLoadFormDynamicFieldList() {
	return useMutation(async (params = {}) => {
		try {
			const userData = getLocalAuthDetails()
			let payload = {
				...params,
				...userData,
				start_from: 0,
				limit_value: 20,
				page_limit: 20,
				page_start: 0,
			}
			let res = await loadFormDynamicFieldList(payload)
			if (res.data.status === 200) {
				const data = res.data.response
				return processFormFields(data) || []
			} else {
				return []
			}
		} catch (error) {
			console.log('Error while loading form fields', error)
			return error
		}
	})
}

// Validate User ID
export function useUserIdValidation() {
	return useMutation(async (params = {}) => {
		try {
			const userData = getLocalAuthDetails()
			let payload = {
				...params,
				...userData,
				start_from: 0,
				limit_value: 20,
				page_limit: 20,
				page_start: 0,
			}
			let res = await userIdValidation(payload)
			if (res.data.status === 200) {
				const data = res.data.response
				return data || []
			} else {
				return [{ message: 'Something Went Wrong!' }]
			}
		} catch (error) {
			console.log('Error while loading form fields', error)
			return error
		}
	})
}

export function useFetchLovDynamicList() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
				...getLocalAuthDetails(),
			}

			let res = await loadfetchDynamicLovList(payload)
			if (res.data.status === 200) {
				if (res.data?.response?.length > 0) {
					const fileData = res.data.response
					const optionList = await prepareLovListOption(fileData)
					res.data.response = optionList
					return res
				} else {
					res.data.response = []
					return res
				}
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useSendSMS() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
			}
			let res = await sendSMS(payload)
			return res.data.status
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useRegenerateCafView() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
			}
			let res = await regenerateCafView(payload)
			return res
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function usePaymentChanelData() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
				page_limit: 50,
				page_start: 0,
				start_from: 0,
				limit_value: 100,
			}
			const res = await PaymentChanelData(payload)
			if (res.data.status === 200) {
				if (res.data.response.length > 0) {
					return res.data.response
				} else {
					return []
				}
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useGetSignature() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await getSignature(payload)
			if (res.data.status === 200) {
				return res.data.response
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useGatewayrouting() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await gatewayrouting(payload)
			if (res.data.status === 200) {
				return res.data.response
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function useCreateOrder() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await createOrder(payload)
			if (res.data.status === 200) {
				return res.data.response
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}

export function usePaymentStatusCheck() {
	return useMutation(async params => {
		try {
			let userData = getLocalAuthDetails()
			let payload = {
				...userData,
				...params,
			}
			const res = await paymentStatusCheck(payload)
			if (res.data.status === 200) {
				return res.data.response
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}
export function useLoadAllFormData() {
	return useMutation(async params => {
		try {
			const { organization_id, account_id, asset_token_auth, asset_id } =
				getLocalAuthDetails()
			const payload = {
				organization_id,
				asset_id,
				asset_token_auth,
				account_id,
				...params,
			}
			const res = await loadAllFormData(payload)
			if (res.status === 200 && res.data.response.length > 0) {
				return res.data.response
			} else {
				return {}
			}
		} catch (err) {
			console.log(err)
			return err
		}
	})
}
export function useDedupeBotCuidCheck() {
	return useMutation(async payload => {
		try {
			let requestData = {
				...payload,
				...getLocalAuthDetails(),
			}
			const res = await workDedupeBotCuidCheck(requestData)
			if (res.status === 200) {
				return res.data.response
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			throw new Error(error.message)
		}
	})
}
